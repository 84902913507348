<style lang="scss">
#printme {
  visibility: hidden;
}
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 75%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .modal-content {
    min-height: 46rem !important;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="xl"
    v-model="showModal"
    :title="'Daily Oven Report'"
  >
    <div class="row" v-if="loadingData">
      <div class="col-12 text-center" style="padding: 10px">
        <loadingSpinner></loadingSpinner>
        <br />
        <h6>Please Wait, we are get the details.</h6>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="row">
          <div class="col-6">
            <label for="">Date:</label>
            <label for="" class="detail ml-2">{{
              ovenReportDetail.date
            }}</label>
          </div>
          <div class="col-6">
            <label for="">Shift:</label>
            <label for="" class="detail ml-2">{{
              ovenReportDetail.shift
            }}</label>
            <button @click="print" class="float-right btn btn-info btn-sm">
              Print
            </button>
          </div>
          <div class="col-6">
            <label for="">Oven Started On: </label>
            <label for="" class="detail ml-2">{{
              ovenReportDetail.oven_started_on
            }}</label>
          </div>

          <!-- <li>
                    <label for="">Date of Birth: </label>
                    <label for="" class="detail">{{
                      appointmentData.dob
                    }}</label>
                  </li> -->

          <div class="col-6">
            <label for="">Oven Supervisor: </label>
            <label for="" class="detail ml-2">{{
              ovenReportDetail.oven_supervisor
            }}</label>
          </div>
          <div class="col-6">
            <label for="">Shift Incharge: </label>
            <label for="" class="detail ml-2">{{
              ovenReportDetail.shift_incharge
            }}</label>
          </div>
        </div>

        <div class="log mt-5" style="min-height: 27rem !important">
          <div class="row">
            <div class="col-md-12" style="background: white">
              <h5 style="text-align: center">Plant-A</h5>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th scope="col">Variety</th>
                    <th scope="col">Started On</th>
                    <th scope="col">Finished at</th>
                    <th scope="col">Break From</th>
                    <th scope="col">Break To</th>
                    <th scope="col">Break Total</th>
                    <th scope="col">Remarks</th>
                  </tr>
                </thead>
                <tbody v-if="!loadingData && plant_A_details.length > 0">
                  <tr v-for="(item, index) in plant_A_details" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.variety }}</td>
                    <td>{{ item.started_on }}</td>
                    <td>{{ item.finished_at }}</td>
                    <td>{{ item.break_from }}</td>
                    <td>{{ item.break_to }}</td>
                    <td>{{ item.total_break }}</td>
                    <td>{{ item.remarks }}</td>
                  </tr>
                </tbody>

                <tbody v-if="!loadingData && plant_A_details.length < 1">
                  <tr>
                    <td colspan="8" class="text-center">No record(s) found</td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="10">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12" style="background: white">
              <h5 style="text-align: center">Plant-B</h5>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th scope="col">Variety</th>
                    <th scope="col">Started On</th>
                    <th scope="col">Finished at</th>
                    <th scope="col">Break From</th>
                    <th scope="col">Break To</th>
                    <th scope="col">Break Total</th>
                    <th scope="col">Remarks</th>
                  </tr>
                </thead>
                <tbody v-if="!loadingData && plant_B_details.length > 0">
                  <tr v-for="(item, index) in plant_B_details" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.variety }}</td>
                    <td>{{ item.started_on }}</td>
                    <td>{{ item.finished_at }}</td>
                    <td>{{ item.break_from }}</td>
                    <td>{{ item.break_to }}</td>
                    <td>{{ item.total_break }}</td>
                    <td>{{ item.remarks }}</td>
                  </tr>
                </tbody>

                <tbody v-if="!loadingData && plant_B_details.length < 1">
                  <tr>
                    <td colspan="8" class="text-center">No record(s) found</td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="10">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2" id="printme">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12 text-center">
            <div class="row">
              <div class="col-md-12">
                <div
                  style="text-align: center; font-size: 2rem; font-weight: 600"
                >
                  {{ currentUser.organization.name }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div style="text-align: center">
                  {{ currentUser.organization.address }}
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <h2 style="text-align: center">
              Shree Pashupati Biscuit Industries (Pvt.) Ltd.
            </h2>
            <h4 style="text-align: center">DUHABI, SUNSARI</h4> -->
              <div class="col-md-12">
                <div
                  style="
                    text-align: center;
                    font-size: 1.5rem;
                    font-weight: 600;
                  "
                >
                  Daily Oven Report
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div class="row">
          <div>
            <div>
              <div>
                <span> Date: {{ ovenReportDetail.date }}</span>
                <span style="margin-left: 150px">
                  Oven Started On: {{ ovenReportDetail.oven_started_on }} </span
                ><span style="float: right"
                  >Shift: {{ ovenReportDetail.shift }}</span
                >
              </div>
            </div>
            <div>
              <span>
                Oven Supervisor: {{ ovenReportDetail.oven_supervisor }}
              </span>

              <span style="float: right"
                >Shift Incharge: {{ ovenReportDetail.shift_incharge }}</span
              >
            </div>
          </div>
        </div>
        <br />
        <div class="log">
          <div class="row">
            <div class="col-md-12">
              <div style="text-align: center">Plant-A</div>
              <table
                class="table table-bordered table-striped"
                style="
                  margin-top: 5px;
                  border: 1px solid black;
                  border-collapse: collapse;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        width: 5%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      SN
                    </th>
                    <th
                      style="
                        width: 12%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Variety
                    </th>
                    <th
                      style="
                        width: 12%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Started On
                    </th>
                    <th
                      style="
                        width: 13%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Finished at
                    </th>
                    <th
                      style="
                        width: 13%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Break From
                    </th>
                    <th
                      style="
                        width: 10%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Break To
                    </th>
                    <th
                      style="
                        width: 12%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Break Total
                    </th>
                    <th
                      style="
                        width: 20%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!loadingData && plant_A_details.length > 0">
                  <tr v-for="(item, index) in plant_A_details" :key="index">
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.variety }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.started_on }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.finished_at }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.break_from }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.break_to }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.total_break }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.remarks }}
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="plant_A_details.length < 1">
                  <tr>
                    <td colspan="8" style="text-align: center">
                      No record(s) found
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="10">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <div style="text-align: center">Plant-B</div>
              <table
                class="table table-bordered table-striped"
                style="
                  margin-top: 5px;
                  border: 1px solid black;
                  border-collapse: collapse;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        width: 5%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      SN
                    </th>
                    <th
                      style="
                        width: 12%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Variety
                    </th>
                    <th
                      style="
                        width: 12%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Started On
                    </th>
                    <th
                      style="
                        width: 13%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Finished at
                    </th>
                    <th
                      style="
                        width: 13%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Break From
                    </th>
                    <th
                      style="
                        width: 10%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Break To
                    </th>
                    <th
                      style="
                        width: 12%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Break Total
                    </th>
                    <th
                      style="
                        width: 20%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                      scope="col"
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!loadingData && plant_B_details.length > 0">
                  <tr v-for="(item, index) in plant_B_details" :key="index">
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.variety }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.started_on }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.finished_at }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.break_from }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.break_to }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.total_break }}
                    </td>
                    <td
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 2px;
                      "
                    >
                      {{ item.remarks }}
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="plant_B_details.length < 1">
                  <tr>
                    <td colspan="8" style="text-align: center">
                      No record(s) found
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="10">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!hasData && !loadingData">
      <div class="col-md-12 col-12" style="padding: 10px">
        <h5>Sorry, we didn't found details</h5>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";

import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    indexNum: {
      type: Number,
      default: -1,
    },
    ovenReportDetail: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      plant_A_details: [],
      plant_B_details: [],
    };
  },

  components: {
    loadingSpinner,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) {
        this.$emit("closemodal");
      }
    });
    if (this.ovenReportDetail.over_report_detail.length > 0) {
      this.plant_A_details = this.ovenReportDetail.over_report_detail.filter(
        (item) => {
          return item.plant == "Plant-A";
        }
      );
      this.plant_B_details = this.ovenReportDetail.over_report_detail.filter(
        (item) => {
          return item.plant == "Plant-B";
        }
      );
    }
  },
  methods: {
    close() {
      this.$emit("closemodal");
    },
    print() {
      var contents = document.getElementById("printme").innerHTML;
      var frame1 = document.createElement("iframe");
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      var frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write(`<html><head>`);
      frameDoc.document.write("</head><body>");
      frameDoc.document.write(contents);
      frameDoc.document.write("</body></html>");
      frameDoc.document.close();
      var css = "@page { size: A4 portrait; }",
        head =
          frameDoc.document.head || document.getElementsByTagName("head")[0],
        style = frameDoc.document.createElement("style");

      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(frameDoc.document.createTextNode(css));
      }

      head.appendChild(style);
      setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
      return false;
    },
  },

  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function (value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      return moment(String(fullDate)).format("h:mm a");
    },
    sDateFormate: function (date) {
      if (date == null) {
        return;
      }
      return moment(String(date)).format("dddd, MMMM DD, YYYY");
    },
  },
};
</script>
